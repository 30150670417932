import React, { useState, useEffect, useContext } from 'react';
import Layout from "../../components/layout";
import QuestionCard from "../../components/questionCard";
import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import FormTable from "../../components/formTable";
import {Box, Typography} from "@material-ui/core";
import { QuestionnaireContext } from "../../context/questionnaireContext";
import { graphql } from 'gatsby';
import theme from '../../gatsby-theme-material-ui-top-layout/theme';

export default function Part1() {
  const {t} = useTranslation();
  const [answer, setAnswer] = useState(null);
  const [nextDisabled, setNextDisable] = useState(true);
  let previousEntry = "";
  const columns = [t("Undergo a prenatal screening test."), t("Equal importance"), t("Choose not to undergo a prenatal screening test.")]
  const rows = [
    [
      t("I would like to know the probability (low or high) of carrying a child with a trisomy."),
      t("Equal importance"),
      t("I would prefer not to know the probability (low or high) of carrying a child with a trisomy.")
    ],
    [
      t("I would be comfortable with experiencing anxiety if my result indicated a high probability that my child had a trisomy."),
      t("Equal importance"),
      t("I would prefer to avoid the anxiety associated with a result indicating a high probability that my child had a trisomy.")
    ],
    [
      t("I would be comfortable with the anxiety of waiting for the results (from the time I undergo the test to the time I receive the results)."),
      t("Equal importance"),
      t("I would prefer to avoid the anxiety of waiting for the results.")
    ],
    [
      t("I would be comfortable with the idea of being falsely reassured or unnecessarily worried given the limitations of the test which may provide a false result."),
      t("Equal importance"),
      t("I would prefer to avoid being falsely reassured or unnecessarily worried given the limitations of the test which may provide a false result.")
    ],
    [
      t("I would like to prepare myself to make a decision when faced with a high probability of carrying a child with a trisomy, such as whether or not to undergo a diagnostic test."),
      t("Equal importance"),
      t("I would prefer to avoid preparing myself to make a difficult decision when faced with a high probability of carrying a child with a trisomy, such as whether or not to undergo a diagnostic test.")
    ],
    [
      t("I would like to be prepared for the arrival of a child with a trisomy."),
      t("Equal importance"),
      t("I would be able to handle being unprepared for the arrival of a child with a trisomy.")
    ],
  ]
  const choices = [
    {value:"Yes", label:t("Undergo a prenatal screening test.")},
    {value:"No", label:t("Choose not to undergo a prenatal screening test.")}
  ]

  if (typeof window !== 'undefined') {
    previousEntry = localStorage.getItem("screening2") || "";
  }
  
  const handleChange = (event) => {
    setAnswer(event.target.value)
  }

  useEffect(() => {
    console.log("screening2: " + answer)
    if(answer !== null)
      setNextDisable(false)
      localStorage.setItem('screening2', answer)
  }, [answer]);

  useEffect(() => {
    if(previousEntry !== "") {
      setAnswer(previousEntry)
    }
  }, []);

  const nextPage = () => {
    return answer === "Yes" ? "/screening/part3" : "/screening/part5"
  }

  return (
    <Layout pageTitle={t("Making my decision")} maxWidth={false} previous="/diagnostic" next={nextPage()} nextDisabled={nextDisabled}>
      <Box marginTop={theme.spacing(0.5)}>
        <Typography variant="body1" gutterBottom={true}>
          <strong>
            {t("Whether or not to undergo the prenatal screening test")}
          </strong>
          <br/> 
          <br/>
          <strong>
            {t("Here are some factors to consider that may influence your decision concerning whether or not to undergo the prenatal screening test.")}
          </strong>
          <br/> 
          <br/>
          {t("Think about what is most important to you.")}
          <br/>
          <br/>
          {t("Blank")}
          <br/>
          <br/>
        </Typography>
      </Box>
      <FormTable rows={rows} columns={columns} part="screening2"></FormTable>
      <QuestionCard question={t("What is your decision?")} choices={choices} answer={answer} handleChange={handleChange}/>
    </Layout>
  );
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
